// src/components/ManageServices.js
import { Add, Edit } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import config from '../../lib/config';
import xhr from '../../lib/xhr';

const facilitiesList = ['GCP', 'WHIS', 'PIO', 'PLM'];

type Service = {
  id: string;
  service_name: string;
  git_repo_url: string;
  gcr_pull_url: string;
  is_continuous_deployment: boolean;
  facilities: string[];
  k8sSelectorLabel: string;
  flux_folder: string;
};

const ManageServices = () => {
  const [services, setServices] = useState([]);
  const [editIdx, setEditIdx] = useState(-1);
  const [editedService, setEditedService] = useState<Service>({
    id: '',
    service_name: '',
    git_repo_url: '',
    gcr_pull_url: '',
    is_continuous_deployment: false,
    facilities: [],
    k8sSelectorLabel: '',
    flux_folder: '',
  });
  const [isNewRow, setIsNewRow] = useState(false);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = () => {
    xhr
      .get(`${config.API_BASE_URL}/api/services`)
      .then((response) => setServices(response.data))
      .catch((error) => console.error('Error fetching services:', error));
  };

  const handleEdit = (idx: number, service: Service) => {
    setEditIdx(idx);
    setEditedService({ ...service });
    setIsNewRow(false);
  };

  const handleAddNew = () => {
    setEditIdx(services.length);
    setEditedService({
      id: '',
      git_repo_url: '',
      service_name: '',
      gcr_pull_url: '',
      is_continuous_deployment: false,
      facilities: [],
      k8sSelectorLabel: '',
      flux_folder: '',
    });
    setIsNewRow(true);
  };

  const handleSave = (id: string) => {
    const request = isNewRow
      ? xhr.post(`${config.API_BASE_URL}/api/services`, editedService)
      : xhr.put(`${config.API_BASE_URL}/api/services/${id}`, editedService);

    request
      .then((response) => {
        fetchServices();
        setEditIdx(-1);
        setIsNewRow(false);
      })
      .catch((error) => console.error('Error saving service:', error));
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setEditedService((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e: any) => {
    const { name, checked } = e.target;
    setEditedService((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleFacilitiesCheckboxChange = (e: any) => {
    const { name, checked } = e.target;
    setEditedService((prevState: any) => {
      const facilities = prevState.facilities || [];
      return {
        ...prevState,
        facilities: checked ? [...facilities, name] : facilities.filter((facility: string) => facility !== name),
      };
    });
  };

  return (
    <div className="manage-services">
      <h2>Manage Services</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ height: 40, background: '#2CB673', color: '#fff' }}>
            <TableRow sx={{ height: 40 }}>
              <TableCell sx={{ padding: '0px 10px', color: '#fff' }}>Service Name</TableCell>
              <TableCell sx={{ padding: '0px 10px', color: '#fff' }}>GCR Pull URL</TableCell>
              <TableCell sx={{ padding: '0px 10px', color: '#fff' }}>Continuous Deployment</TableCell>
              <TableCell sx={{ padding: '0px 10px', color: '#fff' }}>Facilities</TableCell>
              <TableCell sx={{ padding: '0px 10px', color: '#fff' }}>K8s Selector Label</TableCell>
              <TableCell sx={{ padding: '0px 10px', color: '#fff' }}>Flux Folder</TableCell>
              <TableCell sx={{ padding: '0px 10px', color: '#fff' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service: Service, idx) => (
              <TableRow key={service.id} sx={{ height: 40 }}>
                <TableCell sx={{ padding: '0px 10px' }}>
                  {editIdx === idx ? (
                    <TextField
                      name="service_name"
                      value={editedService.service_name}
                      onChange={handleChange}
                      size="small"
                      fullWidth
                      sx={{ height: '30px' }}
                      inputProps={{ style: { height: '30px', padding: '0 10px' } }}
                    />
                  ) : (
                    service.service_name
                  )}
                </TableCell>
                <TableCell sx={{ padding: '0px 10px' }}>
                  {editIdx === idx ? (
                    <TextField
                      name="gcr_pull_url"
                      value={editedService.gcr_pull_url}
                      onChange={handleChange}
                      size="small"
                      fullWidth
                      sx={{ height: '30px' }}
                      inputProps={{ style: { height: '30px', padding: '0 10px' } }}
                    />
                  ) : (
                    service.gcr_pull_url
                  )}
                </TableCell>
                <TableCell sx={{ padding: '0px 10px', textAlign: 'center' }}>
                  {editIdx === idx ? (
                    <Checkbox
                      name="is_continuous_deployment"
                      checked={editedService.is_continuous_deployment}
                      onChange={handleCheckboxChange}
                    />
                  ) : (
                    <Checkbox name="is_continuous_deployment" checked={service.is_continuous_deployment} disabled />
                  )}
                </TableCell>
                <TableCell sx={{ padding: '0px 0px' }}>
                  {editIdx === idx
                    ? facilitiesList.map((facility) => (
                        <div className="mng-srvc-checkbox" key={facility}>
                          <Checkbox
                            name={facility}
                            checked={editedService.facilities?.includes(facility) || false}
                            onChange={handleFacilitiesCheckboxChange}
                          />
                          <label>{facility}</label>
                        </div>
                      ))
                    : facilitiesList.map((facility) => (
                        <div className="mng-srvc-checkbox" key={facility}>
                          <Checkbox
                            name={facility}
                            checked={service.facilities?.includes(facility) || false}
                            disabled
                          />
                          <label>{facility}</label>
                        </div>
                      ))}
                </TableCell>
                <TableCell sx={{ padding: '0px 10px' }}>
                  {editIdx === idx ? (
                    <TextField
                      name="k8sSelectorLabel"
                      value={editedService.k8sSelectorLabel}
                      onChange={handleChange}
                      size="small"
                      fullWidth
                      sx={{ height: '30px' }}
                      inputProps={{ style: { height: '30px', padding: '0 10px' } }}
                    />
                  ) : (
                    service.k8sSelectorLabel
                  )}
                </TableCell>
                <TableCell sx={{ padding: '0px 10px' }}>
                  {editIdx === idx ? (
                    <TextField
                      name="flux_folder"
                      value={editedService.flux_folder}
                      onChange={handleChange}
                      size="small"
                      fullWidth
                      sx={{ height: '30px' }}
                      inputProps={{ style: { height: '30px', padding: '0 10px' } }}
                    />
                  ) : (
                    service.flux_folder
                  )}
                </TableCell>
                <TableCell sx={{ padding: '0px 10px' }}>
                  {editIdx === idx ? (
                    <Button variant="contained" color="primary" onClick={() => handleSave(service.id)}>
                      Save
                    </Button>
                  ) : (
                    <IconButton onClick={() => handleEdit(idx, service)}>
                      <Edit />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {isNewRow && (
              <TableRow sx={{ height: 40 }}>
                <TableCell sx={{ padding: '0px 10px' }}>
                  <TextField
                    name="service_name"
                    value={editedService.service_name}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    sx={{ height: '30px' }}
                    inputProps={{ style: { height: '30px', padding: '0 10px' } }}
                  />
                </TableCell>
                <TableCell sx={{ padding: '0px 10px' }}>
                  <TextField
                    name="gcr_pull_url"
                    value={editedService.gcr_pull_url}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    sx={{ height: '30px' }}
                    inputProps={{ style: { height: '30px', padding: '0 10px' } }}
                  />
                </TableCell>
                <TableCell sx={{ padding: '0px 10px', textAlign: 'center' }}>
                  <Checkbox
                    name="is_continuous_deployment"
                    checked={editedService.is_continuous_deployment}
                    onChange={handleCheckboxChange}
                  />
                </TableCell>
                <TableCell sx={{ padding: '0px 10px' }}>
                  {facilitiesList.map((facility) => (
                    <div key={facility}>
                      <Checkbox
                        name={facility}
                        checked={editedService.facilities?.includes(facility) || false}
                        onChange={handleFacilitiesCheckboxChange}
                      />
                      <label>{facility}</label>
                    </div>
                  ))}
                </TableCell>
                <TableCell sx={{ padding: '0px 10px' }}>
                  <TextField
                    name="k8sSelectorLabel"
                    value={editedService.k8sSelectorLabel}
                    onChange={handleChange}
                    placeholder="app.kubernetes.io/name=THIS"
                    size="small"
                    fullWidth
                    sx={{ height: '30px' }}
                    inputProps={{ style: { height: '30px', padding: '0 10px' } }}
                  />
                </TableCell>
                <TableCell sx={{ padding: '0px 10px' }}>
                  <TextField
                    name="flux_folder"
                    value={editedService.flux_folder}
                    onChange={handleChange}
                    placeholder="flux/clusters/{cluster-name}/THIS i.e fulfil-frapi"
                    size="small"
                    fullWidth
                    sx={{ height: '30px' }}
                    inputProps={{ style: { height: '30px', padding: '0 10px' } }}
                  />
                </TableCell>
                <TableCell sx={{ padding: '0px 10px' }}>
                  <Button variant="contained" color="primary" onClick={() => handleSave('')}>
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <IconButton
        onClick={handleAddNew}
        style={{ position: 'fixed', bottom: '20px', right: '20px', background: '#2CB673', color: '#fff' }}
      >
        <Add />
      </IconButton>
    </div>
  );
};

export default ManageServices;
