import { WarningAmber } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Snackbar,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import config from "../../../../lib/config";
import xhr from "../../../../lib/xhr";
import { useModal } from "../core/Modal/ModalContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ModalContainer = styled(`div`)({
  display: "flex",
  flexDirection: "column",
  gridGap: "1rem",
});

const RollbackWarning = styled(`div`)({
  border: "2px solid goldenrod",
  background: "lightyellow",
  borderRadius: "0.25rem",
  color: "black",
  fontSize: "1.25rem",
  fontWeight: 600,
  display: "flex",
  gridGap: "0.5rem",
  alignItems: "center",
  padding: "0.5rem",
});

const ModalButtonGroup = styled(`div`)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "end",
  gridGap: "1rem",
});

type DeployModalProps = {
  service: {
    facilities: string[];
  };
  commit: {
    service_id: string;
    digest: string;
    tags: string[];
  };
  tag: string;
};

const DeployModal: React.FC<DeployModalProps> = ({ service, commit, tag }) => {
  const { closeModal, confirmText } = useModal();
  const [environments, setEnvironments] = React.useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [creatingCommit, setCreatingCommit] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const handleChange = (event: SelectChangeEvent<typeof environments>) => {
    const {
      target: { value },
    } = event;
    setEnvironments(typeof value === "string" ? value.split(",") : value);
  };

  const handleSubmit = async () => {
    setCreatingCommit(true);
    setSnackbarMessage(`Submitting deploy commit for version ${tag}.`);
    setSnackbarOpen(true);
    try {
      await submitRelease();
      setSnackbarOpen(false);
      setSnackbarOpen(true);
      setSnackbarMessage(`Submitted deployment commit for version ${tag}.`);
    } catch (e: any) {
      setSnackbarMessage(e);
    } finally {
      setCreatingCommit(false);
    }
  };

  const handleSnackbarClose = (event: any, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const submitRelease = () => {
    const payload = {
      service_id: commit.service_id,
      build: commit.digest,
      tag: tag,
      environments,
    };
    return xhr
      .post(`${config.API_BASE_URL}/api/releases`, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error creating release:", error);
        return Promise.reject(
          error.response?.data?.error ||
            "An error occurred while creating the release"
        );
      });
  };

  return (
    <ModalContainer>
      <RollbackWarning>
        <WarningAmber />
        <p>
          Before proceeding, please be aware that this action is irreversible
          and will immediately revert your deployment to a previous state.
        </p>
      </RollbackWarning>
      <FormControl sx={{ mb: 1, width: 300 }}>
        <InputLabel>Environments</InputLabel>
        <Select
          data-testid="environment-select"
          value={environments}
          onChange={handleChange}
          input={<OutlinedInput label="Environments" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {service.facilities.map((facility: string) => (
            <MenuItem
              data-testid={`environment--${facility}`}
              key={facility}
              value={facility}
            >
              <ListItemText primary={facility} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ModalButtonGroup>
        <Button variant="contained" onClick={closeModal}>
          Close
        </Button>
        <Button
          data-testid="deploy-button"
          disabled={creatingCommit || environments.length === 0}
          variant="contained"
          onClick={async () => {
            await handleSubmit();
          }}
        >
          {confirmText}
        </Button>
      </ModalButtonGroup>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert
          data-testid="result-text"
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ModalContainer>
  );
};

export default DeployModal;
