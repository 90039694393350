const _isGitSha = (tag: string) => {
  const hex40Regex = /^[0-9a-f]+$/;
  const SHA_1_LENGTH = 40;
  const SHA_256_LENGTH = 64;
  return hex40Regex.test(tag) && (tag.length === SHA_1_LENGTH || tag.length === SHA_256_LENGTH);
};

const _isSemanticVersion = (tag: string) => {
  const semanticVersionRegex = /^v?[0-9]+\.[0-9]+\.[0-9]+$/;
  return semanticVersionRegex.test(tag);
};

export const isValidDeploymentTag = (tag: string) => _isGitSha(tag) || _isSemanticVersion(tag);
