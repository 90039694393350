import AirIcon from '@mui/icons-material/Air';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import CertifiedIcon from '@mui/icons-material/NewReleases';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';
import { isValidDeploymentTag } from '../../../helpers/deployment';
import config from '../../../lib/config';
import xhr from '../../../lib/xhr';
import { useModal } from './core/Modal/ModalContext';
import DeployModal from './modals/DeployModal';

const steps = [
  { name: 'release cut', icon: ContentCutIcon },
  { name: 'testbook complete', icon: ChecklistIcon },
  { name: 'tested on sim', icon: ApartmentIcon },
  { name: 'tested on whis', icon: AirIcon },
  { name: 'pio stability IT', icon: PrecisionManufacturingIcon },
  { name: 'certified', icon: CertifiedIcon },
  { name: 'released', icon: RocketLaunchIcon },
];

const CommitRow: React.FC<any> = ({ commit, refreshCommits, isContinuousDeployment, deployedMap, service }) => {
  const { openModal } = useModal();
  const [stepStatuses, setStepStatuses] = useState<any>(() => {
    const statuses: any = {};
    steps.forEach((step) => {
      statuses[step.name] = commit.steps?.[step.name]
        ? { completed: true, timestamp: commit.steps[step.name] }
        : { completed: false };
    });
    return statuses;
  });

  // Find a tag that can be used for deployment (i.e., a semantic version or a git SHA)
  const deploymentTag = commit.tags.find((tag: string) => isValidDeploymentTag(tag));

  // Check for a tag used for display purposes (e.g., "release_candidate", "production-release", "latest")
  const displayTag = commit.tags.find((tag: string) => !isValidDeploymentTag(tag)) || commit.tags[0];

  const openDeployModal = () => {
    let titleVersion = `${displayTag} (${deploymentTag})`;
    if (displayTag === deploymentTag) {
      titleVersion = deploymentTag;
    }
    openModal(
      `Deploy ${service.service_name} version ${titleVersion}`,
      <DeployModal service={service} commit={commit} tag={deploymentTag} />,
      'Deploy',
      () => {},
      false
    );
  };

  const toggleStep = (step_name: string) => {
    xhr
      .post(`${config.API_BASE_URL}/api/release-steps/toggle-step`, {
        image_digest: commit.digest,
        step_name,
      })
      .then((response) => {
        setStepStatuses({
          ...stepStatuses,
          [step_name]: { completed: response.data.completed, timestamp: response.data.timestamp },
        });
      })
      .catch((error) => console.error('Error toggling step:', error));
  };

  const { digest, tags, timestamp, releases } = commit;

  const formatTimestamp = (timestamp: string) => {
    const date = new Date(timestamp);
    return date
      .toLocaleString()
      .replace(/,/g, '')
      .replace(/:([0-5]\d)\s/, '-$1 ')
      .toLowerCase();
  };

  const formatDigest = (digest: string) => digest.replace('sha256:', '').substring(0, 12);

  return (
    <>
      <tr>
        <td>
          <img
            src="/docker-ico.png"
            alt="docker icon"
            width="18"
            height="18"
            style={{ verticalAlign: 'middle', marginRight: '5px' }}
          />
          {formatDigest(digest)}
        </td>
        <td>
          {(tags || []).map((tag: string) => (
            <span key={tag} className={`tag-pill ${tag === 'latest' ? 'latest-pill' : ''}`}>
              {tag}
            </span>
          ))}
          {tags &&
            tags.map(
              (tag: string) =>
                deployedMap[tag] &&
                deployedMap[tag].map((location: string) => (
                  <span key={`${tag}-${location}`} className="tag-pill deployed-pill">
                    {location}
                  </span>
                ))
            )}
        </td>
        <td>{timestamp ? formatTimestamp(timestamp.datetime || timestamp) : 'Invalid date'}</td>
        {!isContinuousDeployment && (
          <>
            <td>
              {(releases || []).map((r: { environment: string; timestamp: string }) => (
                <Tooltip key={digest} title={`Deployed on ${formatTimestamp(r.timestamp)}`}>
                  <span key={r.environment} className="tag-pill">
                    {r.environment}
                  </span>
                </Tooltip>
              ))}
            </td>
            <td>
              <Tooltip
                key={digest}
                title={
                  deploymentTag
                    ? 'Commits newTag property in kustomization file with selected version tag.'
                    : "This build is missing a valid deployment tag for deployment. It must have a tag that's either a semantic version or a git SHA."
                }
              >
                {/* need the span so the tooltip can hav an anchor to present the data, without the disabled button doesn't bubble up the tooltip text box */}
                <span>
                  <Button variant="contained" onClick={() => openDeployModal()} disabled={!deploymentTag}>
                    Deploy
                  </Button>
                </span>
              </Tooltip>
            </td>
          </>
        )}
        <td>
          {steps.map((step) => {
            const StepIcon = step.icon;
            return (
              <Tooltip
                key={step.name}
                title={
                  stepStatuses[step.name]?.completed
                    ? `${step.name} completed ${formatTimestamp(stepStatuses[step.name].timestamp)}`
                    : step.name
                }
              >
                <StepIcon
                  onClick={() => toggleStep(step.name)}
                  style={{
                    cursor: 'pointer',
                    marginRight: '5px',
                    color: stepStatuses[step.name]?.completed ? '#2CB673' : 'grey',
                  }}
                />
              </Tooltip>
            );
          })}
        </td>
      </tr>
    </>
  );
};

export default CommitRow;
